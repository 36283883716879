@import "aos/dist/aos.css";
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Yantramanav&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,500;1,400&display=swap");

body {
  margin: 0;
  font-family: "Yantramanav", sans-serif;
  background-color: #111;
  color: #eeeeec !important;
  background-image: url("./Artboard 1111111.webp");
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.heading-font {
  font-family: "Kanit", sans-serif;
}
.heading-cma {
  font-family: "Kanit", sans-serif;
  font-weight: 400;
}

.btn-forex {
  background-image: linear-gradient(
    to right,
    #1fa2ff 0%,
    #12d8fa 51%,
    #1fa2ff 100%
  );
  padding: 15px 45px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;

  border-radius: 10px;
  display: block;
}

.btn-forex:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.scroll-to-top {
  width: 60px !important;
  height: 60px !important;
  background: transparent !important;
  box-shadow: none !important;
}

/* coingeco */
/* .cg-footer{
  visibility: hidden!important;
} */
/* loader */
.loading {
  --speed-of-animation: 0.9s;
  --gap: 6px;
  --first-color: #4c86f9;
  --second-color: #49a84c;
  --third-color: #f6bb02;
  --fourth-color: #f6bb02;
  --fifth-color: #2196f3;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  gap: 6px;
  height: 100px;
}

.loading span {
  width: 4px;
  height: 50px;
  background: var(--first-color);
  animation: scale var(--speed-of-animation) ease-in-out infinite;
}

.loading span:nth-child(2) {
  background: var(--second-color);
  animation-delay: -0.8s;
}

.loading span:nth-child(3) {
  background: var(--third-color);
  animation-delay: -0.7s;
}

.loading span:nth-child(4) {
  background: var(--fourth-color);
  animation-delay: -0.6s;
}

.loading span:nth-child(5) {
  background: var(--fifth-color);
  animation-delay: -0.5s;
}

@keyframes scale {
  0%,
  40%,
  100% {
    transform: scaleY(0.05);
  }

  20% {
    transform: scaleY(1);
  }
}
/* social bar */
.icon-bar {
  position: fixed;
  bottom: 0%;
  -webkit-transform: translateY(0%);
  -ms-transform: translateY(0%);
  transform: translateY(0%);
}

.icon-bar a {
  display: block;
  text-align: center;
  padding: 16px;
  transition: all 0.3s ease;
  color: white;
  font-size: 20px;
}

.facebook:hover {
  background-color: #fff;
  color:  #3B5998;
}
.instagram:hover {
  background-color: #fff;
  color:   #d62976;
}
.google:hover {
  background-color: #fff;
  color:   #dd4b39;
}

.facebook {
  background: #3B5998;
  color: white;
}

.instagram {
  background: #d62976;
  color: white;
}

.google {
  background: #dd4b39;
  color: white;
}

